import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router";
import { RouterEnum } from "../utils/routers";

import ProtectedRoute from "./ProtectedRoute";

const IncomingPayments = React.lazy(() =>
  import("../pages/admin/IncomingPayment/List")
);
const IncomingPaymentDetail = React.lazy(() =>
  import("../pages/admin/IncomingPayment/Detail")
);
const OutgoingPayments = React.lazy(() =>
  import("../pages/admin/OutgoingPayment/List")
);
const OutgoingPaymentDetail = React.lazy(() =>
  import("../pages/admin/OutgoingPayment/Detail")
);
const RefundPayments = React.lazy(() => import("../pages/admin/Refund/List"));
const RefundPaymentsDetail = React.lazy(() =>
  import("../pages/admin/Refund/Detail")
);
const VirtualPayments = React.lazy(() =>
  import("../pages/admin/VirtualPayment/List")
);
const Pos = React.lazy(() => import("../pages/admin/Pos/List"));
const PosDetail = React.lazy(() => import("../pages/admin/Pos/Detail"));
const Extensions = React.lazy(() => import("../pages/admin/Extensions"));
const Analysis = React.lazy(() => import("../pages/admin/Analysis"));
const Monitor = React.lazy(() => import("../pages/admin/Monitor"));
const Workplace = React.lazy(() => import("../pages/admin/Workplace"));
const TransformStatement = React.lazy(() =>
  import("../pages/admin/TransformStatement/List")
);
const UploadStatement = React.lazy(() =>
  import("../pages/admin/UploadStatement")
);
const FDParser = React.lazy(() => import("../pages/admin/Statements/FDParser"));
const Currency = React.lazy(() => import("../pages/admin/Currency/List"));
const Audit = React.lazy(() => import("../pages/admin/Audit"));

const Country = React.lazy(() => import("../pages/admin/Country/List"));
const CountryAdd = React.lazy(() => import("../pages/admin/Country/Add"));
const CountryDetail = React.lazy(() => import("../pages/admin/Country/Detail"));

const City = React.lazy(() => import("../pages/admin/City/List"));
const CityAdd = React.lazy(() => import("../pages/admin/City/Add"));
const CityDetail = React.lazy(() => import("../pages/admin/City/Detail"));

const Region = React.lazy(() => import("../pages/admin/Region/List"));
const RegionAdd = React.lazy(() => import("../pages/admin/Region/Add"));
const RegionDetail = React.lazy(() => import("../pages/admin/Region/Detail"));

const Bank = React.lazy(() => import("../pages/admin/Bank/List"));
const BankAdd = React.lazy(() => import("../pages/admin/Bank/Add"));
const Client = React.lazy(() => import("../pages/admin/Client/List"));
const ClientDetail = React.lazy(() => import("../pages/admin/Client/Detail"));
const Registration = React.lazy(() =>
  import("../pages/admin/Registration/List")
);
const RegistrationDetail = React.lazy(() =>
  import("../pages/admin/Registration/Detail")
);
const Governor = React.lazy(() => import("../pages/admin/Governor/List"));
const GovernorDetail = React.lazy(() =>
  import("../pages/admin/Governor/Detail")
);
const GovernorAdd = React.lazy(() => import("../pages/admin/Governor/Add"));
const Partner = React.lazy(() => import("../pages/admin/Partner/List"));
const PartnerAdd = React.lazy(() => import("../pages/admin/Partner/Add"));
const PartnerDetail = React.lazy(() => import("../pages/admin/Partner/Detail"));
const NotFoundAdmin = React.lazy(() => import("../pages/admin/NotFound"));

const Invoices = React.lazy(() => import("../pages/admin/Invoice/List"));
const PohodaInvoices = React.lazy(() =>
  import("../pages/admin/Invoice/PohodaList")
);
const GenerateTestInvoice = React.lazy(() =>
  import("../pages/admin/Invoice/GenerateTest")
);

const UserCareType = React.lazy(() =>
  import("../pages/admin/UserCareType/List")
);
const UserCareTypeAdd = React.lazy(() =>
  import("../pages/admin/UserCareType/Add")
);
const UserCareTypeDetail = React.lazy(() =>
  import("../pages/admin/UserCareType/Detail")
);

const UserCategoryType = React.lazy(() =>
  import("../pages/admin/UserCategoryType/List")
);
const UserCategoryTypeAdd = React.lazy(() =>
  import("../pages/admin/UserCategoryType/Add")
);
const UserCategoryTypeDetail = React.lazy(() =>
  import("../pages/admin/UserCategoryType/Detail")
);

const GateServiceSettings = React.lazy(() =>
  import("../pages/admin/GateServiceSettings")
);

const PaymentIntents = React.lazy(() =>
  import("../pages/admin/PaymentIntent/List")
);

const PaymentIntentDetail = React.lazy(() =>
  import("../pages/admin/PaymentIntent/Detail")
);

const RecurringPaymentIntents = React.lazy(() =>
  import("../pages/admin/RecurringPaymentIntent/List")
);

const RecurringPaymentIntentDetail = React.lazy(() =>
  import("../pages/admin/RecurringPaymentIntent/Detail")
);

const MerchantCategoryCode = React.lazy(() =>
  import("../pages/admin/MerchantCategoryCode/List")
);
const MerchantCategoryCodeAdd = React.lazy(() =>
  import("../pages/admin/MerchantCategoryCode/Add")
);
const MerchantCategoryCodeDetail = React.lazy(() =>
  import("../pages/admin/MerchantCategoryCode/Detail")
);

const RiskLevel = React.lazy(() => import("../pages/admin/RiskLevel/List"));
const RiskLevelAdd = React.lazy(() => import("../pages/admin/RiskLevel/Add"));
const RiskLevelDetail = React.lazy(() =>
  import("../pages/admin/RiskLevel/Detail")
);

const PayUFirmType = React.lazy(() =>
  import("../pages/admin/PayUFirmType/List")
);
const PayUFirmTypeAdd = React.lazy(() =>
  import("../pages/admin/PayUFirmType/Add")
);
const PayUFirmTypeDetail = React.lazy(() =>
  import("../pages/admin/PayUFirmType/Detail")
);

const RepresentativeType = React.lazy(() =>
  import("../pages/admin/RepresentativeType/List")
);
const RepresentativeTypeAdd = React.lazy(() =>
  import("../pages/admin/RepresentativeType/Add")
);
const RepresentativeTypeDetail = React.lazy(() =>
  import("../pages/admin/RepresentativeType/Detail")
);
const Terminal = React.lazy(() => import("../pages/admin/Terminal/List"));
const TerminalAdd = React.lazy(() => import("../pages/admin/Terminal/Add"));
const TerminalDetail = React.lazy(() =>
  import("../pages/admin/Terminal/Detail")
);
const Company = React.lazy(() => import("../pages/admin/Company/List"));
const CompanyAdd = React.lazy(() => import("../pages/admin/Company/Add"));
const CompanyDetail = React.lazy(() => import("../pages/admin/Company/Detail"));
const SharedBank = React.lazy(() =>
  import("../pages/admin/SharedDataService/Bank/List")
);
const SharedBankDetail = React.lazy(() =>
  import("../pages/admin/SharedDataService/Bank/Detail")
);
const SharedBankAdd = React.lazy(() =>
  import("../pages/admin/SharedDataService/Bank/Add")
);
const SharedLanguage = React.lazy(() =>
  import("../pages/admin/SharedDataService/Language/List")
);
const SharedCurrency = React.lazy(() =>
  import("../pages/admin/SharedDataService/Currency/List")
);
const SharedPaymentMethod = React.lazy(() =>
  import("../pages/admin/SharedDataService/PaymentMethod/List")
);
const SharedPaymentMethodDetail = React.lazy(() =>
  import("../pages/admin/SharedDataService/PaymentMethod/Detail")
);
const SharedBesteronBankAccount = React.lazy(() =>
  import("../pages/admin/SharedDataService/BesteronBankAccount/List")
);
const SharedCountry = React.lazy(() =>
  import("../pages/admin/SharedDataService/Country/List")
);
const SharedCountryDetail = React.lazy(() =>
  import("../pages/admin/SharedDataService/Country/Detail")
);
const SharedHistory = React.lazy(() =>
  import("../pages/admin/SharedDataService/History/List")
);
const PassiveService = React.lazy(() =>
  import("../pages/admin/PassiveService")
);

const FallbackProcessor = React.lazy(() =>
  import("../pages/admin/FallbackProcessor/List")
);
const FallbackProcessorAdd = React.lazy(() =>
  import("../pages/admin/FallbackProcessor/Add")
);
const FallbackProcessorDetail = React.lazy(() =>
  import("../pages/admin/FallbackProcessor/Detail")
);

const PayUAccount = React.lazy(() => import("../pages/admin/PayUAccount/List"));
const PayUAccountAdd = React.lazy(() =>
  import("../pages/admin/PayUAccount/Add")
);
const PayUAccountDetail = React.lazy(() =>
  import("../pages/admin/PayUAccount/Detail")
);
const Account = React.lazy(() => import("../pages/admin/Accounts/List"));
const AccountDetail = React.lazy(() =>
  import("../pages/admin/Accounts/Detail")
);
const Shop = React.lazy(() => import("../pages/admin/Shop/List"));
const ShopDetail = React.lazy(() => import("../pages/admin/Shop/Detail"));

const Forbidden = React.lazy(() => import("../pages/admin/Forbidden"));

const TatraPremium = React.lazy(() =>
  import("../pages/admin/TatraPremium/TokenInfo")
);

const TatraPremiumOatuhRedirect = React.lazy(() =>
  import("../pages/admin/TatraPremium/OauthRedirect")
);

const OnDemandPaymentIntents = React.lazy(() =>
  import("../pages/admin/OnDemandPaymentIntent/List")
);

const OnDemandPaymentIntentDetail = React.lazy(() =>
  import("../pages/admin/OnDemandPaymentIntent/Detail")
);

function Routers(props) {
  const adminRouter = () => {
    return (
      <Switch>
        <ProtectedRoute
          exact
          path={RouterEnum.Analysis.Path}
          component={Analysis}
          requiredRoles={RouterEnum.Analysis.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.Monitor.Path}
          component={Monitor}
          requiredRoles={RouterEnum.Monitor.Roles}
        />
        <ProtectedRoute
          exact
          path="/"
          component={Workplace}
          requiredRoles={RouterEnum.Workplace.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.Workplace.Path}
          component={Workplace}
          requiredRoles={RouterEnum.Workplace.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.IncomingPayments.Path}
          component={IncomingPayments}
          requiredRoles={RouterEnum.IncomingPayments.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.IncomingPaymentDetail.Path}
          component={IncomingPaymentDetail}
          requiredRoles={RouterEnum.IncomingPaymentDetail.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.OutgoingPayments.Path}
          component={OutgoingPayments}
          requiredRoles={RouterEnum.OutgoingPayments.Roles}
        />

        <ProtectedRoute
          exact
          path={RouterEnum.RefundPayments.Path}
          component={RefundPayments}
          requiredRoles={RouterEnum.RefundPayments.Roles}
        />

        <ProtectedRoute
          exact
          path={RouterEnum.RefundPaymentsDetail.Path}
          component={RefundPaymentsDetail}
          requiredRoles={RouterEnum.RefundPaymentsDetail.Roles}
        />

        <ProtectedRoute
          exact
          path={RouterEnum.OutgoingPaymentDetail.Path}
          component={OutgoingPaymentDetail}
          requiredRoles={RouterEnum.OutgoingPaymentDetail.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.VirtualPayments.Path}
          component={VirtualPayments}
          requiredRoles={RouterEnum.VirtualPayments.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.Pos.Path}
          component={Pos}
          requiredRoles={RouterEnum.Pos.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.PosDetail.Path}
          component={PosDetail}
          requiredRoles={RouterEnum.PosDetail.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.Extensions.Path}
          component={Extensions}
          requiredRoles={RouterEnum.Extensions.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.TransformStatement.Path}
          component={TransformStatement}
          requiredRoles={RouterEnum.TransformStatement.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.UploadStatement.Path}
          component={UploadStatement}
          requiredRoles={RouterEnum.UploadStatement.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.FDParser.Path}
          component={FDParser}
          requiredRoles={RouterEnum.FDParser.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.Currency.Path}
          component={Currency}
          requiredRoles={RouterEnum.Currency.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.Country.Path}
          component={Country}
          requiredRoles={RouterEnum.Country.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.CountryAdd.Path}
          component={CountryAdd}
          requiredRoles={RouterEnum.CountryAdd.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.CountryDetail.Path}
          component={CountryDetail}
          requiredRoles={RouterEnum.CountryDetail.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.City.Path}
          component={City}
          requiredRoles={RouterEnum.City.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.CityAdd.Path}
          component={CityAdd}
          requiredRoles={RouterEnum.CityAdd.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.CityDetail.Path}
          component={CityDetail}
          requiredRoles={RouterEnum.CityDetail.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.Region.Path}
          component={Region}
          requiredRoles={RouterEnum.Region.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.RegionAdd.Path}
          component={RegionAdd}
          requiredRoles={RouterEnum.RegionAdd.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.RegionDetail.Path}
          component={RegionDetail}
          requiredRoles={RouterEnum.RegionDetail.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.Bank.Path}
          component={Bank}
          requiredRoles={RouterEnum.Bank.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.BankAdd.Path}
          component={BankAdd}
          requiredRoles={RouterEnum.BankAdd.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.Clients.Path}
          component={Client}
          requiredRoles={RouterEnum.Clients.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.ClientDetail.Path}
          component={ClientDetail}
          requiredRoles={RouterEnum.ClientDetail.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.Registrations.Path}
          component={Registration}
          requiredRoles={RouterEnum.Registrations.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.RegistrationDetail.Path}
          component={RegistrationDetail}
          requiredRoles={RouterEnum.RegistrationDetail.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.Governors.Path}
          component={Governor}
          requiredRoles={RouterEnum.Governors.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.GovernorAdd.Path}
          component={GovernorAdd}
          requiredRoles={RouterEnum.GovernorAdd.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.GovernorDetail.Path}
          component={GovernorDetail}
          requiredRoles={RouterEnum.GovernorDetail.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.Partners.Path}
          component={Partner}
          requiredRoles={RouterEnum.Partners.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.PartnerAdd.Path}
          component={PartnerAdd}
          requiredRoles={RouterEnum.PartnerAdd.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.PartnerDetail.Path}
          component={PartnerDetail}
          requiredRoles={RouterEnum.PartnerDetail.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.Invoices.Path}
          component={Invoices}
          requiredRoles={RouterEnum.Invoices.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.PohodaInvoices.Path}
          component={PohodaInvoices}
          requiredRoles={RouterEnum.PohodaInvoices.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.GenerateTestInvoice.Path}
          component={GenerateTestInvoice}
          requiredRoles={RouterEnum.GenerateTestInvoice.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.UserCareType.Path}
          component={UserCareType}
          requiredRoles={RouterEnum.UserCareType.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.UserCareTypeAdd.Path}
          component={UserCareTypeAdd}
          requiredRoles={RouterEnum.UserCareTypeAdd.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.UserCareTypeDetail.Path}
          component={UserCareTypeDetail}
          requiredRoles={RouterEnum.UserCareTypeDetail.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.UserCategoryType.Path}
          component={UserCategoryType}
          requiredRoles={RouterEnum.UserCategoryType.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.UserCategoryTypeAdd.Path}
          component={UserCategoryTypeAdd}
          requiredRoles={RouterEnum.UserCategoryTypeAdd.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.UserCategoryTypeDetail.Path}
          component={UserCategoryTypeDetail}
          requiredRoles={RouterEnum.UserCategoryTypeDetail.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.Audit.Path}
          component={Audit}
          requiredRoles={RouterEnum.Audit.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.ServiceSettings.Gate.Path}
          component={GateServiceSettings}
          requiredRoles={RouterEnum.ServiceSettings.Gate.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.PaymentIntents.Path}
          component={PaymentIntents}
          requiredRoles={RouterEnum.PaymentIntents.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.PaymentIntentDetail.Path}
          component={PaymentIntentDetail}
          requiredRoles={RouterEnum.PaymentIntentDetail.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.RecurringPaymentIntents.Path}
          component={RecurringPaymentIntents}
          requiredRoles={RouterEnum.RecurringPaymentIntents.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.RecurringPaymentIntentDetail.Path}
          component={RecurringPaymentIntentDetail}
          requiredRoles={RouterEnum.RecurringPaymentIntentDetail.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.MerchantCategoryCode.Path}
          component={MerchantCategoryCode}
          requiredRoles={RouterEnum.MerchantCategoryCode.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.MerchantCategoryCodeAdd.Path}
          component={MerchantCategoryCodeAdd}
          requiredRoles={RouterEnum.MerchantCategoryCodeAdd.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.MerchantCategoryCodeDetail.Path}
          component={MerchantCategoryCodeDetail}
          requiredRoles={RouterEnum.MerchantCategoryCodeDetail.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.RiskLevel.Path}
          component={RiskLevel}
          requiredRoles={RouterEnum.RiskLevel.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.RiskLevelAdd.Path}
          component={RiskLevelAdd}
          requiredRoles={RouterEnum.RiskLevelAdd.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.RiskLevelDetail.Path}
          component={RiskLevelDetail}
          requiredRoles={RouterEnum.RiskLevelDetail.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.PayUFirmType.Path}
          component={PayUFirmType}
          requiredRoles={RouterEnum.PayUFirmType.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.PayUFirmTypeAdd.Path}
          component={PayUFirmTypeAdd}
          requiredRoles={RouterEnum.PayUFirmTypeAdd.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.PayUFirmTypeDetail.Path}
          component={PayUFirmTypeDetail}
          requiredRoles={RouterEnum.PayUFirmTypeDetail.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.RepresentativeType.Path}
          component={RepresentativeType}
          requiredRoles={RouterEnum.RepresentativeType.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.RepresentativeTypeAdd.Path}
          component={RepresentativeTypeAdd}
          requiredRoles={RouterEnum.RepresentativeTypeAdd.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.RepresentativeTypeDetail.Path}
          component={RepresentativeTypeDetail}
          requiredRoles={RouterEnum.RepresentativeTypeDetail.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.Terminal.Path}
          component={Terminal}
          requiredRoles={RouterEnum.Terminal.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.TerminalAdd.Path}
          component={TerminalAdd}
          requiredRoles={RouterEnum.TerminalAdd.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.TerminalDetail.Path}
          component={TerminalDetail}
          requiredRoles={RouterEnum.TerminalDetail.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.Company.Path}
          component={Company}
          requiredRoles={RouterEnum.Company.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.CompanyAdd.Path}
          component={CompanyAdd}
          requiredRoles={RouterEnum.CompanyAdd.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.CompanyDetail.Path}
          component={CompanyDetail}
          requiredRoles={RouterEnum.CompanyDetail.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.SharedBank.Path}
          component={SharedBank}
          requiredRoles={RouterEnum.SharedBank.Roles}
        />
        {/* TODO: vfrolo - create/edit is not allowed yet */}
        {/* <ProtectedRoute
          exact
          path={RouterEnum.SharedBankAdd.Path}
          component={SharedBankAdd}
          requiredRoles={RouterEnum.SharedBankAdd.Roles}
        /> */}
        <ProtectedRoute
          exact
          path={RouterEnum.SharedBankDetail.Path}
          component={SharedBankDetail}
          requiredRoles={RouterEnum.SharedBankDetail.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.SharedLanguage.Path}
          component={SharedLanguage}
          requiredRoles={RouterEnum.SharedLanguage.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.SharedCurrency.Path}
          component={SharedCurrency}
          requiredRoles={RouterEnum.SharedCurrency.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.SharedPaymentMethod.Path}
          component={SharedPaymentMethod}
          requiredRoles={RouterEnum.SharedPaymentMethod.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.SharedPaymentMethodDetail.Path}
          component={SharedPaymentMethodDetail}
          requiredRoles={RouterEnum.SharedPaymentMethodDetail.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.SharedBesteronBankAccount.Path}
          component={SharedBesteronBankAccount}
          requiredRoles={RouterEnum.SharedBesteronBankAccount.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.SharedCountry.Path}
          component={SharedCountry}
          requiredRoles={RouterEnum.SharedCountry.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.SharedCountryDetail.Path}
          component={SharedCountryDetail}
          requiredRoles={RouterEnum.SharedCountryDetail.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.SharedHistory.Path}
          component={SharedHistory}
          requiredRoles={RouterEnum.SharedHistory.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.PayUAccount.Path}
          component={PayUAccount}
          requiredRoles={RouterEnum.PayUAccount.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.PayUAccountAdd.Path}
          component={PayUAccountAdd}
          requiredRoles={RouterEnum.PayUAccountAdd.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.PayUAccountDetail.Path}
          component={PayUAccountDetail}
          requiredRoles={RouterEnum.PayUAccountDetail.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.PassiveService.Path}
          component={PassiveService}
          requiredRoles={RouterEnum.PassiveService.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.FallbackProcessor.Path}
          component={FallbackProcessor}
          requiredRoles={RouterEnum.FallbackProcessor.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.FallbackProcessorAdd.Path}
          component={FallbackProcessorAdd}
          requiredRoles={RouterEnum.FallbackProcessorAdd.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.FallbackProcessorAdd.Path}
          component={FallbackProcessorAdd}
          requiredRoles={RouterEnum.FallbackProcessorAdd.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.FallbackProcessorDetail.Path}
          component={FallbackProcessorDetail}
          requiredRoles={RouterEnum.FallbackProcessorDetail.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.Accounts.Path}
          component={Account}
          requiredRoles={RouterEnum.Accounts.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.AccountDetail.Path}
          component={AccountDetail}
          requiredRoles={RouterEnum.AccountDetail.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.Shop.Path}
          component={Shop}
          requiredRoles={RouterEnum.Shop.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.ShopDetail.Path}
          component={ShopDetail}
          requiredRoles={RouterEnum.ShopDetail.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.TatraPremium.Path}
          component={TatraPremium}
          requiredRoles={RouterEnum.TatraPremium.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.TatraPremiumOatuhRedirect.Path}
          component={TatraPremiumOatuhRedirect}
          requiredRoles={RouterEnum.TatraPremiumOatuhRedirect.Roles}
        />
        <Route
          exact
          path={RouterEnum.Forbidden.Path}
          render={() => <Forbidden />}
        />
        <Route
          exact
          path={RouterEnum.Login.Path}
          render={() => <Redirect to={"/"} />}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.OnDemandPaymentIntents.Path}
          component={OnDemandPaymentIntents}
          requiredRoles={RouterEnum.OnDemandPaymentIntents.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.OnDemandPaymentIntentDetail.Path}
          component={OnDemandPaymentIntentDetail}
          requiredRoles={RouterEnum.OnDemandPaymentIntentDetail.Roles}
        />
        <Route render={() => <NotFoundAdmin />} />
      </Switch>
    );
  };

  return adminRouter();
}

export default Routers;
